import { createEffect, createEvent, createStore, sample } from 'effector';
import { NotificationManager } from 'react-notifications';
import {
  updateHallLayoutFx,
  FormSubmitData,
  HallLayout,
  fetchHallLayoutFx,
} from 'shared/api/reference/hall-layouts';
import { fetchHallFx } from 'shared/api/reference/halls';
import { createToggler } from 'shared/lib/toggler';

const toggler = createToggler();

const formSubmitted = createEvent<FormSubmitData>();

const $loading = createStore(true);
const $data = createStore<HallLayout | null>(null);
const $modalData = createStore<{ id: number; hall: number } | null>(null);

const successNotificationFx = createEffect(() => {
  NotificationManager.success('Успешно');
});

const failNotificationFx = createEffect(() => {
  NotificationManager.error('Ошибка');
});

export const updateSchemeModal = { toggler, formSubmitted, $data, $loading };

// load
sample({
  clock: toggler.open,
  target: fetchHallLayoutFx,
});

sample({
  clock: toggler.open,
  target: $modalData,
});

sample({
  clock: fetchHallLayoutFx.done,
  fn: ({ result }) => result,
  target: $data,
});

sample({
  clock: fetchHallLayoutFx.done,
  fn: () => false,
  target: $loading,
});

sample({
  clock: toggler.close,
  fn: () => true,
  target: $loading,
});

sample({
  clock: toggler.close,
  fn: () => null,
  target: [$data, $modalData],
});

// update & notifications
sample({
  source: {
    modalData: $modalData,
  },
  clock: formSubmitted,
  fn: ({ modalData }, { name }) => ({
    id: modalData!.id,
    data: {
      hall_id: modalData!.hall,
      name,
    },
  }),
  target: updateHallLayoutFx,
});

sample({
  source: {
    modalData: $modalData,
  },
  clock: updateHallLayoutFx.done,
  fn: ({ modalData }) => ({ id: modalData!.hall, type: 'view' }),
  target: [toggler.close, fetchHallFx, successNotificationFx],
});

sample({
  clock: updateHallLayoutFx.fail,
  target: failNotificationFx,
});
