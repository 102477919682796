import React from 'react';
import { Field } from 'react-final-form';
import Button from 'components/Button';
import Form from 'components/Form';
import { Input } from 'components/FormControls';
import Modal from 'components/Modal';
import { FormSubmitData, HallLayout } from 'shared/api/reference/hall-layouts';
import LOCALES from 'shared/constants/LOCALES';
import { required, composeValidators, maxValue } from 'shared/helpers/validations';
import { useTranslation } from '../../../../i18n';

interface FormProps {
  isEdit?: boolean;
  onChangeLng?: (lng: string) => void;
  onSubmit?: (data: FormSubmitData) => void;
  initialValues?: HallLayout | null;
  closeModal: () => void;
}

export const HallLayoutForm: React.FC<FormProps> = ({ closeModal, isEdit, ...rest }) => {
  const { t } = useTranslation([LOCALES.REFERENCES]);

  return (
    <Form
      {...rest}
      withoutNotification
      render={({ handleSubmit }: any) => (
        <form onSubmit={handleSubmit}>
          <Modal.Title>
            {isEdit ? t('references:halls.edit_scheme') : t('references:halls.create_scheme')}
          </Modal.Title>
          <Field component="input" name="hall_id" type="hidden" />
          <Field name="name" validate={composeValidators(required, maxValue(200))}>
            {({ input, meta }) => <Input label={t('references:halls.name')} {...input} meta={meta} />}
          </Field>
          <Modal.Footer>
            {isEdit ? (
              <>
                <Button type="submit">{t('forms:edit')}</Button>
                <Button transparent type="button" onClick={closeModal}>
                  {t('forms:cancel')}
                </Button>
              </>
            ) : (
              <>
                <Button type="submit">{t('forms:create')}</Button>
                <Button transparent type="button" onClick={closeModal}>
                  {t('forms:cancel')}
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      )}
    />
  );
};
