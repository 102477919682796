import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import React from 'react';
import { BADGE_STATUSES, ORDER_STATUSES, PURCHASE_STATUSES, STATUS_IN_QUERY } from 'shared/constants/ORDER';
import routes from 'shared/helpers/routes';
import { groupTickets, renderTickets } from 'shared/helpers/tickets';
import StatusBadge from '../../StatusBadge';
import TextOverflow from '../../TextOverflow';
import Tooltip from '../../Tooltip';
import Styled from './styles';

interface OrdersProps {
  isOpen: boolean;
  mobileFixed?: boolean;
  toggleOrders?: () => void;
  data: any[];
}

const ExternalOrders: React.FC<OrdersProps> = ({ data, isOpen, mobileFixed, toggleOrders }) => (
  <>
    <Styled.ModalClose mobileFixed={mobileFixed} isOpen={isOpen} onClick={toggleOrders} />
    <Styled.Container mobileFixed={mobileFixed} isOpen={isOpen}>
      {map(data, (order) => {
        let manager = null;
        const { tickets } = order;
        const { groupedTickets } = groupTickets(tickets);

        if (get(order, 'manager.firstname') && get(order, 'manager.lastname')) {
          manager = `${order.manager.firstname} ${order.manager.lastname}`;
        }

        const queryStatus = STATUS_IN_QUERY[order.status];
        const query = `?id=${order.id}${queryStatus ? `&status__in=${queryStatus}` : ''}`;
        const href = `${order.destination === 'purchases' ? routes.purchases : routes.sales}${query}`;

        return (
          <Styled.Item key={order.id}>
            <Styled.Info>
              <Styled.Row>
                {order.id ? (
                  <Styled.Link target="_blank" rel="noopener noreferrer" href={href}>
                    {order.id}
                  </Styled.Link>
                ) : (
                  <Styled.NotAllowed>
                    Нет доступа
                    <br />к заказу
                  </Styled.NotAllowed>
                )}
                <Tooltip text={manager} placement="topLeft">
                  <Styled.Manager>
                    <TextOverflow>{manager}</TextOverflow>
                  </Styled.Manager>
                </Tooltip>
              </Styled.Row>
              <Styled.Rows>
                {map(groupedTickets, (sector, sectorName) =>
                  map(sector, (row) => {
                    const rowText = (
                      <>
                        {`${sectorName} • ${
                          head<any>(row).row ? `Ряд: ${head<any>(row).row} •` : ''
                        } ${`Места: `}`}
                        {renderTickets(row)}
                      </>
                    );

                    return (
                      <Tooltip key={sectorName} text={rowText} placement="topLeft">
                        <Styled.Row>{rowText}</Styled.Row>
                      </Tooltip>
                    );
                  }),
                )}
              </Styled.Rows>
            </Styled.Info>
            <StatusBadge type={BADGE_STATUSES[order.status]}>
              {order.status === ORDER_STATUSES.TICKET_SEARCH ? 'Поиск' : PURCHASE_STATUSES[order.status]}
            </StatusBadge>
          </Styled.Item>
        );
      })}
    </Styled.Container>
  </>
);

export default ExternalOrders;
