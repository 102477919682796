import debouncePromise from 'debounce-promise';
import omit from 'lodash/omit';
import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { companySources } from 'entities/company';
import {
  ANNOUNCEMENTS_VISIBLE_SCOPE_TYPE_OPTIONS,
  STANDING_ANNOUNCEMENTS_VISIBLE_SCOPE_TYPE_OPTIONS,
} from 'shared/constants/ANNOUNCEMENT';
import { ANNOUNCEMENTS_TYPES } from 'shared/constants/ANNOUNCEMENT_TYPES';
import CURRENCY_CHAR_CODES from 'shared/constants/CURRENCY_CHAR_CODES';
import FRAGMENT_TYPES from 'shared/constants/FRAGMENT_TYPES';
import { TICKET_TYPES } from 'shared/constants/TICKET_TYPES';
import { getCurrency, getCurrencyByCharCode, getCurrencyById } from 'shared/helpers/currency';
import { renderSortedSectors, renderSortedRowsNumbers } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  seatValidation,
  required,
  minNumberValue,
  maxNumberValue,
  intValidation,
  createSeatsValidation,
} from 'shared/helpers/validations';
import expansionSources from 'shared/sources/expansion';
import playgroundsSources from 'shared/sources/playgrounds';
import Button from '../../../../Button';
import Form from '../../../../Form';
import { Input, InputWithSelect, RadioGroup, Checkbox, NumberInput, Select } from '../../../../FormControls';
import Modal from '../../../../Modal';
import Spinner from '../../../../Spinner';
import AnnouncementPrices from './AnnouncementPrices';
import Styled from './styles';

interface ModalProps {
  isEdit?: boolean;
  isCategories?: boolean;
  initialValues?: any;
  close: () => void;
  deleteAnnouncement?: (_: any, id: number) => any;
  event: any;
  onSubmit: (data: any) => any;
  setSeatsIds: () => void;
  isCertificate?: boolean;
}

interface IState {
  isLoading: boolean;
  currency?: any;
  defaultCurrency: any;
}

const AnnouncementsModal: React.FC<ModalProps> = (props) => {
  const {
    isEdit = false,
    close,
    event,
    onSubmit,
    setSeatsIds,
    deleteAnnouncement,
    initialValues = {},
    isCertificate,
  } = props;
  const [state, setState] = useState<IState>({
    isLoading: true,
    currency: null,
    defaultCurrency: null,
  });

  useEffect(() => {
    const fetchCurrency = async () => {
      const { options } = await getCurrency();
      let currency;
      if (initialValues && initialValues.currency) {
        currency = getCurrencyById(options, initialValues.currency.value);
      } else {
        currency = getCurrencyByCharCode(options, CURRENCY_CHAR_CODES.RUB);
      }

      setState({
        isLoading: false,
        defaultCurrency: { currency },
      });
    };

    fetchCurrency().catch((err) => new Error(err));
  }, []);

  const seatsValidation = debouncePromise(
    createSeatsValidation({
      event,
      setSeatsIds,
    }),
    500,
  );

  const handleFormSubmit = (data) => {
    let request = data;

    if (data.is_categories === 'true') {
      request = omit(request, ['sector', 'row', 'seat', 'count']);
    }

    if (data.is_categories === 'false') {
      request = omit(request, ['category', 'count_categories']);
    }

    return onSubmit(request);
  };

  const modalContent = (
    <>
      {!isEdit && <Modal.Close onClick={close} />}
      <Styled.Container>
        <Styled.Title>{isEdit ? 'Редактирование объявления' : 'Добавление билетов'}</Styled.Title>
        <Form
          onSubmit={handleFormSubmit}
          initialValues={{
            is_categories: 'false',
            ...initialValues,
            ...state.defaultCurrency,
            is_certificate: isCertificate,
          }}
          validate={seatsValidation}
          render={({ handleSubmit, values, form, pristine, invalid, submitting, validating }) => {
            const isCategories = values.is_categories === 'true';
            const isStand = values.count;

            if (!values.row && values.seat && !values.count) {
              form.change('seat', null);
            }

            return (
              <form
                onSubmit={(data) => {
                  handleSubmit(data);

                  if (!isEdit) {
                    if (!pristine && !invalid) {
                      form.reset();
                    }
                  }
                }}
              >
                <Styled.Table>
                  {!isCertificate && (
                    <Styled.TableRow>
                      <Styled.TableCellTitle>Форма билетов</Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Field name="is_categories" validate={required}>
                          {({ input, meta }) => (
                            <RadioGroup
                              {...input}
                              meta={meta}
                              buttons={[
                                { id: 0, value: 'false', name: 'Физические места' },
                                { id: 1, value: 'true', name: 'Категории' },
                              ]}
                              disabled={isEdit}
                            />
                          )}
                        </Field>
                      </Styled.TableCell>
                    </Styled.TableRow>
                  )}

                  <Styled.TableRow>
                    <Styled.TableCellTitle>
                      Данные билетов
                      <Styled.TableCellTitleSubText>
                        {isCategories ? (
                          'Категория билетов'
                        ) : (
                          <>
                            Информация <br /> о местах
                          </>
                        )}
                      </Styled.TableCellTitleSubText>
                    </Styled.TableCellTitle>
                    <Styled.TableCell>
                      {isCategories ? (
                        <Styled.Categories>
                          <Field name="category" validate={required}>
                            {({ input, meta }) => (
                              <InputWithSelect
                                isAsync
                                route={playgroundsSources}
                                query={{
                                  hall_layout_id: event?.hall_layout_id,
                                  fragment_type: FRAGMENT_TYPES.CATEGORY,
                                }}
                                optionsRenderer={renderSortedSectors}
                                label="Категория"
                                defaultValue={false}
                                withoutPagination
                                disabled={isEdit}
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                          <Field
                            name="count_categories"
                            validate={composeValidators(
                              required,
                              intValidation,
                              minNumberValue(1),
                              maxNumberValue(10000),
                            )}
                          >
                            {({ input, meta }) => (
                              <NumberInput
                                label="Кол-во"
                                type="number"
                                disabled={isEdit}
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                        </Styled.Categories>
                      ) : (
                        <>
                          <Styled.FieldsRow>
                            <Field name="sector" validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  label="Сектор"
                                  route={playgroundsSources}
                                  query={{
                                    hall_layout_id: event?.hall_layout_id,
                                    fragment_type: FRAGMENT_TYPES.SECTOR,
                                  }}
                                  optionsRenderer={renderSortedSectors}
                                  withoutPagination
                                  disabled={values.row || isEdit}
                                  {...input}
                                  meta={meta}
                                />
                              )}
                            </Field>
                            {!isCertificate && (
                              <Field name="row" validate={values.seat && required}>
                                {({ input, meta }) => (
                                  <Styled.Row>
                                    <InputWithSelect
                                      isAsync
                                      label="Ряд"
                                      route={playgroundsSources}
                                      query={{
                                        hall_layout_id: event?.hall_layout_id,
                                        fragment_type: FRAGMENT_TYPES.ROW,
                                        parent_id: values.sector && values.sector.value,
                                      }}
                                      optionsRenderer={renderSortedRowsNumbers}
                                      disabled={!values.sector || isEdit}
                                      defaultOptions={false}
                                      withoutPagination
                                      {...input}
                                      meta={meta}
                                    />
                                  </Styled.Row>
                                )}
                              </Field>
                            )}
                          </Styled.FieldsRow>
                          <Styled.FieldsRow>
                            <Styled.Tickets>
                              {!values.count && !isCertificate && (
                                <Field name="seat" validate={seatValidation}>
                                  {({ input, meta }) => (
                                    <Input
                                      label="Места"
                                      {...input}
                                      meta={meta}
                                      disabled={isEdit || !values.row}
                                    />
                                  )}
                                </Field>
                              )}
                              {!values.seat && (
                                <Field
                                  name="count"
                                  validate={composeValidators(
                                    required,
                                    intValidation,
                                    minNumberValue(1),
                                    maxNumberValue(10000),
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <NumberInput
                                      label="Кол-во"
                                      type="number"
                                      disabled={isEdit}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                              )}
                            </Styled.Tickets>
                          </Styled.FieldsRow>
                        </>
                      )}
                    </Styled.TableCell>
                  </Styled.TableRow>

                  {!isCertificate && !isCategories && (
                    <Styled.TableRow>
                      <Styled.TableCellTitle>
                        Видимость объявления
                        <Styled.TableCellTitleSubText>
                          Какие параметры отображать на виджете
                        </Styled.TableCellTitleSubText>
                      </Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Field name="view_scope_type">
                          {({ input, meta }) => (
                            <Select
                              options={
                                isStand
                                  ? STANDING_ANNOUNCEMENTS_VISIBLE_SCOPE_TYPE_OPTIONS
                                  : ANNOUNCEMENTS_VISIBLE_SCOPE_TYPE_OPTIONS
                              }
                              {...input}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.TableCell>
                    </Styled.TableRow>
                  )}

                  {!isCertificate && (
                    <Styled.TableRow>
                      <Styled.TableCellTitle>
                        Тип билетов
                        <Styled.TableCellTitleSubText>Признаки билетов</Styled.TableCellTitleSubText>
                      </Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Field name="announcement_type" validate={required}>
                          {({ input, meta }) => (
                            <RadioGroup
                              {...input}
                              meta={meta}
                              buttons={[
                                { id: 0, value: '0', name: ANNOUNCEMENTS_TYPES[0] },
                                { id: 1, value: '1', name: ANNOUNCEMENTS_TYPES[1] },
                              ]}
                            />
                          )}
                        </Field>
                        <Field name="ticket_type" validate={required}>
                          {({ input, meta }) => (
                            <Styled.TicketSigns>
                              <RadioGroup
                                {...input}
                                meta={meta}
                                buttons={[
                                  { id: 1, value: '1', name: TICKET_TYPES[1] },
                                  { id: 2, value: '2', name: TICKET_TYPES[2] },
                                  { id: 3, value: '3', name: TICKET_TYPES[3] },
                                ]}
                              />
                            </Styled.TicketSigns>
                          )}
                        </Field>
                        <Styled.Checkboxes>
                          <Field name="personal" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Именной
                              </Checkbox>
                            )}
                          </Field>
                          <Field name="non_returnable" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Невозвратный
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.Checkboxes>
                      </Styled.TableCell>
                    </Styled.TableRow>
                  )}

                  <Styled.TableRow>
                    <Styled.TableCellTitle>
                      Поставщик и цены
                      <Styled.TableCellTitleSubText>
                        Поставщик и цены за одну штуку билета
                      </Styled.TableCellTitleSubText>
                    </Styled.TableCellTitle>

                    <Styled.TableCell>
                      {!isCertificate && (
                        <Styled.Provider>
                          <Field name="provider">
                            {({ input, meta }) => (
                              <InputWithSelect
                                isAsync
                                label="Поставщик"
                                route={companySources}
                                routeName="providers"
                                {...input}
                                meta={meta}
                              />
                            )}
                          </Field>
                        </Styled.Provider>
                      )}
                      <AnnouncementPrices
                        currency={values.currency}
                        isEdit={isEdit}
                        isCertificate={isCertificate}
                      />
                    </Styled.TableCell>
                  </Styled.TableRow>

                  {!isCertificate && (
                    <Styled.TableRow>
                      <Styled.TableCellTitle>
                        Прочее
                        <Styled.TableCellTitleSubText>
                          Дополнительная информация к билету
                        </Styled.TableCellTitleSubText>
                      </Styled.TableCellTitle>
                      <Styled.TableCell>
                        <Field name="expansion">
                          {({ input, meta }) => (
                            <InputWithSelect
                              isAsync
                              isMulti
                              route={expansionSources}
                              label="Доп услуги"
                              {...input}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </Styled.TableCell>
                    </Styled.TableRow>
                  )}
                </Styled.Table>

                <Modal.Footer>
                  <Button transparent type="button" onClick={close}>
                    Отмена
                  </Button>
                  {initialValues && !isEdit && (
                    <Button dangerTransparent type="button" onClick={deleteAnnouncement} data={values.id}>
                      Удалить
                    </Button>
                  )}
                  <Button disabled={submitting || validating} data-selenium="announcement-add">
                    {initialValues ? 'Сохранить' : 'Добавить'}
                  </Button>
                </Modal.Footer>
              </form>
            );
          }}
        />
      </Styled.Container>
    </>
  );

  if (state.isLoading) {
    return (
      <Modal.Body>
        <Spinner center />
      </Modal.Body>
    );
  }

  return !isEdit ? <Modal.Body>{modalContent}</Modal.Body> : modalContent;
};

export default AnnouncementsModal;
