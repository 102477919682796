import map from 'lodash/map';
import React, { Fragment, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import LangSwitcher from 'components/LangSwitcher';
import CallContext from 'containers/CallContext';
import HeaderSearchContainer from 'containers/HeaderSearchContainer';
import HeaderSidebarIcon from 'containers/HeaderSidebarIcon';
import NotificationsContainer from 'containers/NotificationsContainer';
import { UserProfileIcon, selectAccount } from 'entities/account';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { HeaderOrderContext } from './HeaderOrderContext';
import Styled from './styles';

interface HeaderProps {
  title: string | ReactElement;
  breadcrumbs: ReactElement[];
  isDesktop?: boolean;
}

const TEMP_HIDE_NOTIFICATIONS = 94;

const Header = (props: HeaderProps) => {
  const { title, breadcrumbs, isDesktop = true } = props;
  const user = useSelector(selectAccount);

  return (
    <>
      <Styled.Container>
        <HeaderSidebarIcon />
        {title && <Styled.Title>{title}</Styled.Title>}
        {breadcrumbs && (
          <Styled.Breadcrumbs>
            {map(breadcrumbs, (breadcrumb, index) => {
              if (index !== breadcrumbs.length - 1) {
                return <Fragment key={index}>{breadcrumb}</Fragment>;
              }

              return isDesktop && <Fragment key={index}>{breadcrumb}</Fragment>;
            })}
          </Styled.Breadcrumbs>
        )}
        <HeaderSearchContainer />
        <Styled.RightButtons>
          <CallContext />
          {user?.company?.id !== TEMP_HIDE_NOTIFICATIONS && <NotificationsContainer />}
          <LangSwitcher />
          <UserProfileIcon />
        </Styled.RightButtons>
        <HeaderOrderContext />
      </Styled.Container>
      {breadcrumbs && !isDesktop && (
        <Styled.Breadcrumbs mobile>
          {map(breadcrumbs, (breadcrumb, index) => {
            if (index !== breadcrumbs.length - 1) {
              return null;
            }
            return <Fragment key={index}>{breadcrumb}</Fragment>;
          })}
        </Styled.Breadcrumbs>
      )}
    </>
  );
};

export default withCheckIsMobile(Header);
