import React from 'react';
import { Field } from 'react-final-form';
import isEmpty from 'lodash/isEmpty';
import { NotificationManager } from 'react-notifications';
import ClockIcon from '../../../../static/icons/clock.svg';
import DELIVERY_TYPES from 'shared/constants/DELIVERY_TYPES';
import { ORDER_STATUSES } from 'shared/constants/ORDER';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { maxValue } from 'shared/helpers/validations';
import { renderMembers } from 'shared/helpers/optionsRenderers';
import { checkForInitialValues } from 'shared/helpers/form';
import { companySources } from 'entities/company';
import {
  InputWithSelect,
  NumberInput,
  DateTimePicker,
  Input,
  FormDescription,
  SmallFormDescription,
  FormRow,
} from '../../FormControls';
import Modal from '../../Modal';
import Form from '../../Form';
import Button from '../../Button';
import Styled from './styles';
import { ModalFunctions } from '../../../../interfaces/modal';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  createDelivery: (id: number, data: any) => any;
  order: any;
  data: {
    onSuccess: (data: any) => any;
    initialValues?: any;
  };
}

const OrderEditDeliveryModal: React.FC<ModalProps> = ({
  order,
  closeModal,
  createDelivery,
  data: { onSuccess, initialValues = {} },
}) => {
  const isTimeStatus = order.status === ORDER_STATUSES.TIME;
  const saveDelivery = (data) => {
    const deliveryData = {
      subway: data.subway,
      delivery_time: data.delivery_time,
      house_num: data.house_num,
      entrance: data.entrance,
      apartment: data.apartment,
      price: data.price || 0,
      ...(data.delivery_date && { delivery_date: data.delivery_date.format(DATE_FORMATS.DATE) }),
      ...(!isEmpty(data.courier) && { courier_id: data.courier.value }),
      ...(data.address && { address: data.address }),
    };

    let updatedData = deliveryData;

    if (!isEmpty(initialValues)) {
      updatedData = checkForInitialValues(initialValues, updatedData, null);
    }

    return createDelivery(order.id, {
      ...updatedData,
      method: DELIVERY_TYPES.COURIER,
    }).then((response) => {
      if (response.error) {
        NotificationManager.error('Ошибка');

        return response;
      }

      onSuccess(deliveryData);

      closeModal();

      return response;
    });
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Styled.Title>Настройка доставки курьером</Styled.Title>
      <Form
        onSubmit={saveDelivery}
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key: string]: string } = {};

          if (!values.address && (!values.courier || isEmpty(values.courier)) && !values.price) {
            errors.address = 'Поле обязательно для заполнения';
          }

          return errors;
        }}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Styled.Row>
              <FormDescription width={160}>
                Адрес доставки
                <SmallFormDescription>Информация о местоположении</SmallFormDescription>
              </FormDescription>
              <Styled.Fields>
                <FormRow>
                  <Field name="address">
                    {({ input, meta }) => <Input label="Адрес доставки" meta={meta} {...input} />}
                  </Field>
                </FormRow>
                <FormRow>
                  <Field name="subway" validate={maxValue(255)}>
                    {({ input, meta }) => <Input label="Станция метро" {...input} meta={meta} />}
                  </Field>
                </FormRow>
                <FormRow>
                  <Styled.Controls>
                    <Styled.Column>
                      <Field name="house_num" validate={maxValue(255)}>
                        {({ input, meta }) => <Input label="Номер дома" {...input} meta={meta} />}
                      </Field>
                    </Styled.Column>
                    <Styled.Column>
                      <Field name="apartment" validate={maxValue(255)}>
                        {({ input, meta }) => <Input label="Квартира" {...input} meta={meta} />}
                      </Field>
                    </Styled.Column>
                  </Styled.Controls>
                </FormRow>
                <FormRow>
                  <Styled.Controls>
                    <Styled.Column>
                      <Field name="entrance" validate={maxValue(255)}>
                        {({ input, meta }) => <Input label="Подъезд" {...input} meta={meta} />}
                      </Field>
                    </Styled.Column>
                  </Styled.Controls>
                </FormRow>
              </Styled.Fields>
            </Styled.Row>
            <Styled.Row>
              <FormDescription width={160}>
                Курьер и дата
                <SmallFormDescription>Выбор курьера и даты доставки</SmallFormDescription>
              </FormDescription>
              <Styled.Fields>
                <FormRow>
                  <Field name="courier">
                    {({ input, meta }) => (
                      <InputWithSelect
                        isAsync
                        label="Курьер"
                        route={companySources}
                        routeName="courierList"
                        optionsRenderer={renderMembers}
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                </FormRow>
                <FormRow>
                  <Styled.Controls>
                    <Styled.Column>
                      <Styled.DateField>
                        <Field name="delivery_date">
                          {({ input, meta }) => (
                            <DateTimePicker
                              label="Дата"
                              name="delivery_date"
                              outdatedToggler={false}
                              withoutTimePick
                              format={DATE_FORMATS.DATE_PREVIEW}
                              handleForm={form}
                              input={input}
                              meta={meta}
                              withClearButton
                            />
                          )}
                        </Field>
                      </Styled.DateField>
                    </Styled.Column>
                    <Styled.Column>
                      <Field name="delivery_time" validate={maxValue(255)}>
                        {({ input, meta }) => (
                          <Input icon={<ClockIcon />} label="Время" {...input} meta={meta} />
                        )}
                      </Field>
                    </Styled.Column>
                  </Styled.Controls>
                </FormRow>

                <Styled.Column noPadding>
                  <Styled.Price>
                    <Field name="price">
                      {({ input, meta }) => (
                        <NumberInput
                          label="Стоимость"
                          type="number"
                          {...input}
                          meta={meta}
                          max={1000000}
                          icon="₽"
                        />
                      )}
                    </Field>
                  </Styled.Price>
                </Styled.Column>
              </Styled.Fields>
            </Styled.Row>
            <Modal.Footer contentWidth="880px">
              <Button type="button" transparent onClick={closeModal} disabled={isTimeStatus}>
                Отмена
              </Button>
              <Button type="submit" disabled={isTimeStatus}>
                Готово
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default OrderEditDeliveryModal;
