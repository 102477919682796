import React from 'react';
import { Field } from 'react-final-form';
import padStart from 'lodash/padStart';
import Form from '../../../../Form';
import { Radio } from '../../../../FormControls';
import Variant from '../Variant';
import ConstructorStyled from '../styles';
import ConstructorFooter from '../ConstructorFooter';
import Styled from './styles';
import icons from './icons';

const COLORS = {
  0: '#000000',
  1: '#2F80ED',
  2: '#F09E00',
  3: '#EB5757',
  4: '#2DC472',
};

// @ts-ignore
const otherIcons = new Array(65).fill();

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const FaviconStep: React.FC<StepProps> = ({ data, onSubmit, updateData, goBack }) => (
  <ConstructorStyled.Container withTitle>
    <ConstructorStyled.Header>
      <ConstructorStyled.Title>Выберите дизайн Фавиконки</ConstructorStyled.Title>
    </ConstructorStyled.Header>
    <Styled.Content>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...data,
          favicon_type: data.favicon_type === '2' ? '0' : data.favicon_type,
        }}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <Styled.ControlsHeader>
              <Styled.Controls>
                <Styled.Label>Форма:</Styled.Label>
                <Styled.Control>
                  <Field name="favicon_type" type="radio" value="0">
                    {({ input, meta }) => <Radio color="#000" square {...input} meta={meta} checkValue />}
                  </Field>
                </Styled.Control>
                <Styled.Control>
                  <Field name="favicon_type" type="radio" value="1">
                    {({ input, meta }) => <Radio color="#000" {...input} meta={meta} checkValue />}
                  </Field>
                </Styled.Control>
              </Styled.Controls>
              <Styled.Controls>
                <Styled.Label>Цвет:</Styled.Label>
                <Styled.Control>
                  <Field name="favicon_color" type="radio" value="0">
                    {({ input, meta }) => (
                      <Radio color={COLORS[0]} square {...input} meta={meta} checkValue />
                    )}
                  </Field>
                </Styled.Control>
                <Styled.Control>
                  <Field name="favicon_color" type="radio" value="1">
                    {({ input, meta }) => (
                      <Radio color={COLORS[1]} square {...input} meta={meta} checkValue />
                    )}
                  </Field>
                </Styled.Control>
                <Styled.Control>
                  <Field name="favicon_color" type="radio" value="2">
                    {({ input, meta }) => (
                      <Radio color={COLORS[2]} square {...input} meta={meta} checkValue />
                    )}
                  </Field>
                </Styled.Control>
                <Styled.Control>
                  <Field name="favicon_color" type="radio" value="3">
                    {({ input, meta }) => (
                      <Radio color={COLORS[3]} square {...input} meta={meta} checkValue />
                    )}
                  </Field>
                </Styled.Control>
                <Styled.Control>
                  <Field name="favicon_color" type="radio" value="4">
                    {({ input, meta }) => (
                      <Radio color={COLORS[4]} square {...input} meta={meta} checkValue />
                    )}
                  </Field>
                </Styled.Control>
              </Styled.Controls>
            </Styled.ControlsHeader>
            <Styled.List>
              {icons.map((icon, index) => (
                <Variant
                  key={`${values.favicon_type}${values.favicon_color}${padStart(`${index}`, 2, '00')}`}
                  name="ui_config_favicon"
                  value={`${values.favicon_type}${values.favicon_color}${padStart(`${index}`, 2, '00')}`}
                  withoutLabel
                  withoutFormat
                >
                  <Styled.Preview bg={COLORS[values.favicon_color]} square={values.favicon_type === '0'}>
                    {icon}
                  </Styled.Preview>
                </Variant>
              ))}
            </Styled.List>

            <Styled.ControlsHeader mt={32}>
              <Styled.Controls>
                <Styled.Label>Остальные</Styled.Label>
              </Styled.Controls>
            </Styled.ControlsHeader>
            <Styled.List>
              {otherIcons.map((_, icon) => {
                const iconIndex = icon + 1 < 10 ? `200${icon + 1}` : `20${icon + 1}`;
                return (
                  <Variant
                    key={iconIndex}
                    name="ui_config_favicon"
                    value={iconIndex}
                    withoutLabel
                    withoutFormat
                  >
                    <Styled.ImagePreview
                      src={`/static/constructor/favicon/${iconIndex}.png`}
                      alt={iconIndex}
                    />
                  </Variant>
                );
              })}
            </Styled.List>

            <ConstructorFooter form={form} updateData={updateData} goBack={goBack} />
          </form>
        )}
      />
    </Styled.Content>
  </ConstructorStyled.Container>
);

export default FaviconStep;
