import React from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import Styled from './styles';
import { ModalFunctions } from '../../../../interfaces/modal';
import Modal from '../../Modal';
import Form from '../../Form';
import { required } from 'shared/helpers/validations';
import Button from '../../Button';
import { DateTimePicker, TimePicker } from '../../FormControls';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import { successTimePayment } from 'entities/payment';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
}

const OrderPaymentTimeModal: React.FC<ModalProps> = ({ data, closeModal }) => {
  const dispatch = useDispatch();
  const onSubmit = (values: any) => {
    const date = values.date.format('YYYY-MM-DD');
    const time = values.time.format('HH:mm:ss');

    // @ts-ignore
    dispatch(successTimePayment(data.order.id, { paid_at: `${date}T${time}` })).then(() => {
      data.updateOrder();
      closeModal();
    });
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title isSmall className="modal__title">
        Время получения оплаты
      </Modal.Title>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Styled.Fields>
              <Field name="date" validate={required}>
                {({ input, meta }) => (
                  <DateTimePicker
                    label="Дата"
                    name="date"
                    inModal
                    withoutTimePick
                    format={DATE_FORMATS.DATE_PREVIEW}
                    disabledDate={() => {}}
                    handleForm={form}
                    input={input}
                    meta={meta}
                    withClearButton
                  />
                )}
              </Field>
              <Field name="time" validate={required}>
                {({ input, meta }) => <TimePicker label="Время" input={input} meta={meta} defaultNowTime />}
              </Field>
            </Styled.Fields>
            <Modal.Footer mobileRow className="modal__footer">
              <Button type="button" transparent onClick={closeModal}>
                Отмена
              </Button>
              <Button disabled={pristine}>Готово</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default OrderPaymentTimeModal;
