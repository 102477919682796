import { useUnit } from 'effector-react';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import Button from 'components/Button';
import ModalFooter from 'components/ModalFooter';
import permissions from 'config/permissions';
import ReferenceRelated from 'widgets/reference/ReferenceRelated';
import { HallTranslation } from 'shared/api/reference/halls';
import routes from 'shared/helpers/routes';
import { findTranslation } from 'shared/helpers/translations';
import { useToggler } from 'shared/lib/toggler';
import { Modal, ModalClose } from 'shared/ui/modal';
import { Spinner } from 'shared/ui/spinner';
import { createSchemeModal } from '../../create-scheme-modal';
import { updateSchemeModal } from '../../update-scheme-modal';
import { viewHallModal } from '../model';
import Styled from './styles';

export const ViewHallModal = () => {
  const { t, i18n } = useTranslation();
  const { open: openAddSchemeModal } = useToggler(createSchemeModal.toggler);
  const { open: openUpdateSchemeModal } = useToggler(updateSchemeModal.toggler);
  const { modalOpened, modalClosed, handleDeleteScheme } = useUnit({
    modalOpened: viewHallModal.modalOpened,
    modalClosed: viewHallModal.modalClosed,
    handleDeleteScheme: viewHallModal.deleteSchemeSubmitted,
  });
  const { data, loading, relatedHallLayouts, relatedHallLayoutsLoading, modalId, isOpen } = useUnit({
    data: viewHallModal.$data,
    loading: viewHallModal.$loading,
    relatedHallLayouts: viewHallModal.$relatedHallLayouts,
    relatedHallLayoutsLoading: viewHallModal.$relatedHallLayoutsLoading,
    modalId: viewHallModal.$modalId,
    isOpen: viewHallModal.toggler.$isOpen,
  });
  const handleEditHallLayout = (_: any, id: number) => openUpdateSchemeModal({ id, hall: data!.id });
  const handleDeleteHallLayout = (_: any, id: number) => handleDeleteScheme(id);

  useEffect(() => {
    if (isOpen && modalId) {
      modalOpened({ id: modalId, type: 'view' });
    }

    return () => {
      modalClosed();
    };
  }, [isOpen]);

  const hallInfo = data ? (findTranslation(data.info, i18n.language) as HallTranslation) : undefined;

  return (
    <Modal toggler={viewHallModal.toggler} name="hall-view" id={modalId}>
      {({ forceCloseModal }) => (
        <Styled.Body>
          <ModalClose onClick={forceCloseModal} />
          {loading ? (
            <Styled.SpinnerContainer>
              <Spinner />
            </Styled.SpinnerContainer>
          ) : (
            <>
              <Styled.Title>{hallInfo?.title}</Styled.Title>
              <Styled.Info>{data?.place.title}</Styled.Info>
              <Styled.Hr />
              <Styled.Description dangerouslySetInnerHTML={{ __html: hallInfo!.description }} />
              <ReferenceRelated
                isLoading={relatedHallLayoutsLoading}
                count={relatedHallLayouts?.count}
                data={relatedHallLayouts?.results}
                href={routes.hallLayout}
                permission={permissions.editor}
                deleteHallLayout={handleDeleteHallLayout}
                editHallLayout={handleEditHallLayout}
                name={t('references:halls.schemes')}
                withButtons
              />

              <ModalFooter>
                <Button onClick={forceCloseModal} type="button">
                  {t('forms:close')}
                </Button>
                <Button transparent onClick={openAddSchemeModal} type="button">
                  {t('references:halls.add_scheme')}
                </Button>
              </ModalFooter>
            </>
          )}
        </Styled.Body>
      )}
    </Modal>
  );
};
