import React, { useState } from 'react';
import Form from '../../../Form';
import Tabs from '../../../Tabs';
import Variant from './Variant';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import { getSelectedTab } from 'shared/helpers/constructor';

const TABS = [
  {
    text: 'Текстом',
    key: 1,
  },
  {
    text: 'Иконка',
    key: 2,
  },
];

const LOGOS = Array.from({ length: 20 }, (v, i) => i + 1);

const ICONS = Array.from({ length: 77 }, (v, i) => i + 21);

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
}

const LogoStep: React.FC<StepProps> = ({ data, onSubmit, updateData, goBack }) => {
  const [tab, setTab] = useState(getSelectedTab(data.ui_config_logo, LOGOS));

  return (
    <Styled.Container withTabs>
      <Styled.Header withTabs>
        <Styled.Title>Выберите логотип</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Tabs tabs={TABS} onSelect={setTab} selectedTab={tab}>
          <Form
            onSubmit={onSubmit}
            initialValues={data}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ListWrapper>
                  {tab === 1 && (
                    <Styled.List grid>
                      {LOGOS.map((logo) => (
                        <Styled.Item logo key={logo}>
                          <Variant name="ui_config_logo" value={logo}>
                            <img
                              width="auto"
                              height="50px"
                              src={`/static/constructor/logo_new/${logo}.svg`}
                              alt=""
                            />
                          </Variant>
                        </Styled.Item>
                      ))}
                    </Styled.List>
                  )}
                  {tab === 2 && (
                    <Styled.List grid>
                      {ICONS.map((icon) => (
                        <Styled.Item logo key={icon}>
                          <Variant name="ui_config_logo" value={icon}>
                            <img src={`/static/constructor/logo_new/${icon}.svg`} alt="" />
                          </Variant>
                        </Styled.Item>
                      ))}
                    </Styled.List>
                  )}
                </Styled.ListWrapper>
                <ConstructorFooter goBack={goBack} form={form} updateData={updateData} />
              </form>
            )}
          />
        </Tabs>
      </Styled.Content>
    </Styled.Container>
  );
};

export default LogoStep;
