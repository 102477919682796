import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import config from 'config/config';
import api from '../../../services/api';
import { CreateHallLayoutData, HallLayout, HallLayoutsParams, PaginatedHallLayouts } from './types';

const route = `${config.TICKETS_API_URL}/admin/v1/hall-layouts`;

export const fetchHallLayoutsFx = createEffect<HallLayoutsParams, PaginatedHallLayouts, AxiosError>(
  async (params) => {
    const response = await api.get(route, {
      params,
    });

    return response.data;
  },
);

export const fetchHallLayoutFx = createEffect(async ({ id }: { id: number }): Promise<HallLayout> => {
  const response = await api.get(`${route}/${id}`);

  return response.data;
});

export const createHallLayoutFx = createEffect(async (data: CreateHallLayoutData): Promise<HallLayout> => {
  const response = await api.post(route, data);

  return response.data;
});

export const updateHallLayoutFx = createEffect(
  async ({ id, data }: { id: number; data: CreateHallLayoutData }): Promise<HallLayout> => {
    const response = await api.patch(`${route}/${id}`, data);

    return response.data;
  },
);

export const deleteHallLayoutFx = createEffect(async (id: number) => {
  const response = await api.delete(`${route}/${id}`);

  return response;
});
