import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { orderSources } from 'entities/order';
import { ModalFunctions } from '../../../../interfaces/modal';
import WarningIcon from '../../../../static/icons/warning.svg';
import Button from '../../Button';
import Modal from '../../Modal/Modal';
import ExternalOrders from '../../Order/ExternalOrders';
import Styled from './styles';

interface ModalProps {
  data: { tickets: number[] };
  closeModal: ModalFunctions['closeModal'];
}

const OrderUpdatesModal: React.FC<ModalProps> = (props) => {
  const { tickets } = props.data;
  const [orders, setOrders] = useState<any>(null);

  useEffect(() => {
    Promise.all(tickets.map((orderId) => api.get(orderSources.detail(orderId)))).then((response) =>
      setOrders(response.map((action) => action.data)),
    );
  }, []);

  return (
    <Styled.Container>
      <Modal.Title>Произошла ошибка</Modal.Title>
      <Styled.Tickets>
        {!orders ? (
          <Skeleton active style={{ margin: '0 0 16px' }} />
        ) : (
          <ExternalOrders data={orders} isOpen />
        )}
      </Styled.Tickets>
      <Styled.Warning>
        <WarningIcon />В заказе есть билеты, которые уже используются в других заказах, необходимо их удалить
        или заменить на другие, перед тем как заказ вернётся в продажу
      </Styled.Warning>
      <Modal.Footer>
        <Button type="button" onClick={props.closeModal}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Styled.Container>
  );
};

export default OrderUpdatesModal;
