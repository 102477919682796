import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { eventsSources } from 'shared/api/events';
import { composeValidators, required, eventWithHall } from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import api from 'shared/services/api';
import { ModalFunctions } from '../../../../interfaces/modal';
import Button from '../../Button';
import Form from '../../Form';
import { EventSelector } from '../../FormControls';
import Modal from '../../Modal/Modal';
import Spinner from '../../Spinner';
import Styled from './styles';

interface ModalProps {
  data: {
    id?: string | number;
    event?: {
      id?: string | number;
    };
  };
  closeModal: ModalFunctions['closeModal'];
  editInvoiceEvent: (id: string | number, data: any) => any;
  isMobile: boolean;
}

const EditEventModal: React.FC<ModalProps> = (props) => {
  const { data, closeModal, isMobile } = props;
  const { id, event: { id: eventId } = {} } = data;

  const [event, setEvent] = useState<any>({
    data: {},
    state: {
      isLoading: true,
      isFail: false,
    },
  });

  useEffect(() => {
    const loadEvent = async () => {
      const response = await api.get(eventsSources.childDetail(eventId! as number));

      setEvent({
        data: head(response.data.results),
        state: {
          isLoading: false,
          isFail: false,
        },
      });
    };

    if (eventId) {
      loadEvent().catch((err) => new Error(err));
    } else {
      setEvent({
        data: {},
        state: {
          isLoading: false,
          isFail: false,
        },
      });
    }
  }, []);

  const onSubmit = (formData: any) => props.editInvoiceEvent(id!, { event_date_id: formData.event.value });

  const initialValues =
    eventId && !isEmpty(event.data)
      ? {
          event: {
            data: event.data,
            value: event.data.id,
            name: event.data.title,
            label: event.data.title,
          },
        }
      : {};

  if (event.state.isLoading) {
    return (
      <Styled.Container>
        <Styled.GlobalStyles />
        <Spinner />
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Form
        onSubmit={onSubmit}
        onSuccess={closeModal}
        initialValues={initialValues}
        render={({ handleSubmit }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <Modal.Title>Редактирование мероприятия</Modal.Title>
            <Field name="event" validate={composeValidators(required, eventWithHall)}>
              {({ input, meta }) => (
                <EventSelector input={input} meta={meta} menuPlacement={isMobile ? 'top' : 'bottom'} />
              )}
            </Field>
            <Modal.Footer>
              <Button transparent type="button" onClick={closeModal}>
                Отмена
              </Button>
              <Button>Сохранить</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default withCheckIsMobile(EditEventModal);
