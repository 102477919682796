import config from 'config/config';
import COOKIES from '../../constants/COOKIES';
import { getCookie } from '../../lib/cookie';
import api from '../../services/api';

const companyId = getCookie(COOKIES.COMPANY);

interface Info {
  contact_info_address: string;
  language_code: string;
  main_page_banner_sub_text: string;
  main_page_banner_text: string;
  reseller: string;
  site_name: string;
  ui_config_block_name_address: string;
  ui_config_block_name_afisha: string;
  ui_config_block_name_archive: string;
  ui_config_block_name_contact: string;
  ui_config_block_name_faq: string;
  ui_config_block_name_gallery: string;
  ui_config_block_name_issue: string;
  ui_config_block_name_news: string;
  ui_config_block_name_top_afisha: string;
  ui_config_custom_pages_group_name: string;
  ui_config_qna_name: string;
  ui_config_show_additional_name: string;
  ui_config_show_additional_link_second: string;
  ui_config_show_additional_name_second: string;
  ui_config_show_afisha_name: string;
  ui_config_show_archive_name: string;
  ui_config_show_media_name: string;
  ui_config_show_news_name: string;
  ui_config_show_persons_name: string;
  ui_config_show_places_name: string;
  ui_config_show_repertoire_name: string;
}
interface IUpdateLandingConstructorInfo {
  tech_name: string;
  language_code: string;
  info: Info;
}

interface ICreateLandingConstructorInfo {
  tech_name: string;
  info: Info;
}

export default {
  root: `${config.COMMON_API_URL}/admin/v1/${companyId}/landings`,
  phones: 'landing/site/phones',
  newDetail: (techName: string) => `${config.COMMON_API_URL}/admin/v2/${companyId}/landings/${techName}`,
  detail: (techName: string) => `${config.COMMON_API_URL}/admin/v2/${companyId}/landings/${techName}`,
  info: (techName: string) => `${config.COMMON_API_URL}/admin/v2/${companyId}/landings/${techName}/info`,

  bulkUpdateQna: 'landing/qna/bulk',
  languages: `${config.COMMON_API_URL}/admin/v1/languages`,

  async createQnA(id: number, qnaforUpdate: any) {
    const promises = qnaforUpdate.map((qnaforUpdateId) => {
      return api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${id}/qna`, {
        qna_id: qnaforUpdateId,
      });
    });

    return Promise.all(promises);
  },

  async deleteQnA(id: number[], qnaforDelete: number[]) {
    const promises = qnaforDelete.map((qnaforDeleteId) => {
      return api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${id}/qna/${qnaforDeleteId}`,
      );
    });

    return Promise.all(promises);
  },

  async updateLandingConstructorInfo({
    tech_name,
    language_code,
    info,
  }: IUpdateLandingConstructorInfo): Promise<Info> {
    const response = await api.patch(
      `${config.COMMON_API_URL}/admin/v2/${companyId}/landings/${tech_name}/info/${language_code}`,
      info,
    );

    return response.data;
  },

  async createLandingConstructorInfo({ tech_name, info }: ICreateLandingConstructorInfo): Promise<Info> {
    const response = await api.post(
      `${config.COMMON_API_URL}/admin/v2/${companyId}/landings/${tech_name}/info`,
      info,
    );

    return response.data;
  },

  async deleteConstructorAttachments({ tech_name, id }) {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${tech_name}/attachments/${id}`,
    );

    return response;
  },

  async createConstructorAttachments({ tech_name, attachments }) {
    const response = api.post(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${tech_name}/attachments`,
      attachments,
    );

    return response;
  },

  async updateConstructorGalleryAttachments({ tech_name, attachments, attachment_type_id }) {
    const response = api.patch(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${tech_name}/attachments/${attachment_type_id}`,
      attachments,
    );

    return response;
  },
};
