import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;

    @media (max-width: ${BREAKPOINTS.XL}) {
      margin-left: 0;
    }
  `,
};
